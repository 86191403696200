import bg from './assets/background.svg'
import img from './assets/img.svg'

function App() {
  return (
    <div className='background' style={{
      'background': `url(${bg}) center center/cover no-repeat`,
      'backgroundColor': '#52682F',
      'width': '100vw',
      'height': '100vh'
    }}>
      <div className='image'>
        <img src={img} alt="" style={{ 'width': '90%' }} />
      </div>
    </div>
  );
}

export default App;
